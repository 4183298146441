var Config = {
  base_url: window.location.origin,
  template: 'MARTIN',
  mapInfoWindow: false,
  listIcon: 'caret',
  page: 'home',
  image_url: '/images/MARTIN/',
  mapStyles: {
      default: [],

      light: [{"featureType":"landscape","elementType":"all","stylers":[{"visibility":"on"},{"color":"#cacdcf"},{"lightness":"60"}]},{"featureType":"landscape.man_made","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.attraction","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.government","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.medical","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dbe5ba"}]},{"featureType":"poi.place_of_worship","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"geometry","stylers":[{"visibility":"simplified"}]},{"featureType":"transit.line","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#b5dbef"}]}],

      prestige: [{"featureType":"all","elementType":"geometry.fill","stylers":[{"weight":"2.00"}]},{"featureType":"all","elementType":"geometry.stroke","stylers":[{"color":"#9c9c9c"}]},{"featureType":"all","elementType":"labels.text","stylers":[{"visibility":"on"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"landscape.man_made","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"color":"#eeeeee"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#7b7b7b"}]},{"featureType":"road","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#46bcec"},{"visibility":"on"}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#c8d7d4"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#070707"}]},{"featureType":"water","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]}],

      espace_pro: [{"featureType":"administrative.province","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","elementType":"all","stylers":[{"saturation":-100},{"lightness":51},{"visibility":"simplified"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"saturation":-100},{"lightness":30},{"visibility":"on"}]},{"featureType":"road.local","elementType":"all","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},{"featureType":"transit","elementType":"all","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"water","elementType":"all","stylers":[{"saturation":"4"},{"weight":"10.00"},{"visibility":"on"},{"hue":"#0fff00"}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ab3d85"},{"saturation":"0"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":-25},{"saturation":-100}]},{"featureType":"water","elementType":"labels.text","stylers":[{"hue":"#ff0000"}]}],

      foundation: [{"featureType":"all","elementType":"geometry.fill","stylers":[{"weight":"2.00"}]},{"featureType":"all","elementType":"geometry.stroke","stylers":[{"color":"#9c9c9c"}]},{"featureType":"all","elementType":"labels.text","stylers":[{"visibility":"on"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"landscape.man_made","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"color":"#eeeeee"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#7b7b7b"}]},{"featureType":"road","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#46bcec"},{"visibility":"on"}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#d7d2cd"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#070707"}]},{"featureType":"water","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]}],
      
      kevin: [{"featureType": "administrative","elementType": "all","stylers": [{"saturation": "-100"}]},{"featureType": "administrative.province","elementType": "all","stylers": [{"visibility": "off"}]},{"featureType": "landscape","elementType": "all","stylers": [{"saturation": -100},{"lightness": 65},{"visibility": "on"}]},{"featureType": "poi","elementType": "all","stylers": [{"saturation": -100},{"lightness": "50"},{"visibility": "simplified"}]},{"featureType": "road","elementType": "all","stylers": [{"saturation": "-100"}]},{"featureType": "road.highway","elementType": "all","stylers": [{"visibility": "simplified"}]},{"featureType": "road.arterial","elementType": "all","stylers": [{"lightness": "30"}]},{"featureType": "road.local","elementType": "all","stylers": [{"lightness": "40"}]},{"featureType": "transit","elementType": "all","stylers": [{"saturation": -100},{"visibility": "simplified"}]},{"featureType": "water","elementType": "geometry","stylers": [{"hue": "#ffff00"},{"lightness": -25},{"saturation": -97}]},{"featureType": "water","elementType": "labels","stylers": [{"lightness": -25},{"saturation": -100}]}
    ]
  },
  mapStyleType: [],
  clusterImageURL: 'https://cdn.rawgit.com/googlemaps/js-marker-clusterer/gh-pages/images/m'
};

var BrowserInfo = {
    isChrome: /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor),
    isSafari: /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor),
    isFirefox: /firefox/i.test(navigator.userAgent),
    isIE: navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) || (typeof $.browser !== "undefined" && $.browser.msie == 1),
    isEdge: navigator.userAgent.match(/Edge/),
    isMicrosoft: navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/Edge/),// Edge || Explorer
    isiOS: /(iPad|iPhone|iPod)/g.test(navigator.userAgent),
    isiPhone: /(iPhone|iPod)/g.test(navigator.userAgent),
    isiPad: /iPad/i.test(navigator.userAgent),
    isAndroid: /android/i.test(navigator.userAgent),
    isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(navigator.userAgent)
};

var GoogleMap = (function() {
    "use strict";
    var mapDiv, map, markerCluster, minZoomLevel = 7, MARKERS = [], REFS = [], mapInitialized = false, lastOpened;
    function init() {
        MARKERS = [];
        mapDiv = document.getElementById('map');
        map = null;
        if(Config.template == 'UNUSED') {
            map = new google.maps.Map(mapDiv, {
                center: {lat: 46.7703501, lng: 2.0304763},
                zoom: minZoomLevel,
                scrollwheel: false,
                draggable: (BrowserInfo.isMobile ? false : true),
                streetViewControl: false,
                //mapTypeId: google.maps.MapTypeId.TERRAIN,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                    mapTypeIds: [
                        google.maps.MapTypeId.ROADMAP,
                        google.maps.MapTypeId.TERRAIN
                    ]
                },
                styles: Config.mapStyles[Config.mapStyleType]
            });
            google.maps.event.addListener(map, 'zoom_changed', function () {
                if (map.getZoom() < minZoomLevel) map.setZoom(minZoomLevel);
            });
        }
        $('.ui-property-msc, .ui-property').each(function() {
            if($(this).data('lat')) {
                REFS.push($(this).attr('id'));
                if (Config.template == 'UNUSED') {
                    createMarker({lat: $(this).data('lat'), lng: $(this).data('lng')}, $(this));
                    $(this).hover(
                        function () {
                            if(lastOpened) { lastOpened.close(); }
                            bounceMarker($(this).attr('id'));

                            if(Config.template == 'UNUSED') {
                                // Ici lorsqu'on met le hover sur une prop, on veut que si le pin est dans un groupe de pin,
                                // on identifie le pin group avec une animation

                                for(var i=0, clusterLen = markerCluster.clusters_.length;i<clusterLen;i++) {
                                    var markersLen = markerCluster.clusters_[i].markers_.length;
                                    if(markersLen > 1) { // Ici on voit sur le cluster est un marker de type groupe, il contient plus de 1, donc groupe CQFD
                                        for(var j=0; j<markersLen; j++) {
                                            // On parcourt tous les markers pour s'assurer que .map est null, donc le marker est bien caché.
                                            if(markerCluster.clusters_[i].markers_[j].map == null) {
                                                // Ensuite on récupérer la propriété et on regarde si la prop est la meme que celle qu'on survole dans la liste
                                                if($(markerCluster.clusters_[i].markers_[j].property).attr('id') == $(this).attr('id')) {
                                                    $(markerCluster.clusters_[i].clusterIcon_.div_).addClass('anim');
                                                    // On anime le group
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        }, function () {
                            if (map !== null) {
                                for (var i = 0; i < MARKERS.length; i++) {
                                    MARKERS[i].setAnimation(null);
                                }
                            }

                            if(Config.template == 'UNUSED') {
                                for(var i=0, clusterLen = markerCluster.clusters_.length;i<clusterLen;i++) {
                                    var markersLen = markerCluster.clusters_[i].markers_.length;
                                    if(markersLen > 1) { // Ici on voit sur le cluster est un marker de type groupe, il contient plus de 1, donc groupe CQFD
                                        for(var j=0; j<markersLen; j++) {
                                            // On parcourt tous les markers pour s'assurer que .map est null, donc le marker est bien caché.
                                            if(markerCluster.clusters_[i].markers_[j].map == null) {
                                                // Ensuite on récupérer la propriété et on regarde si la prop est la meme que celle qu'on survole dans la liste
                                                if($(markerCluster.clusters_[i].markers_[j].property).attr('id') == $(this).attr('id')) {
                                                    $(markerCluster.clusters_[i].clusterIcon_.div_).removeClass('anim');
                                                    // On anime le group
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    );
                }
            }
        });
        if(Config.template == 'UNUSED') {
            var clusterStyles = [{
                textColor: 'white',
                height: 75,
                width: 75,
                textSize:20
            },
            {
                textColor: 'white',
                height: 75,
                width: 75,
                textSize:20
            },
            {
                textColor: 'white',
                height: 75,
                width: 75,
                textSize:20
            }];

            var markerCluster = new MarkerClusterer(map, MARKERS, {
                maxZoom: 20,
                styles: clusterStyles
            });
            
            // Lorsque l'on met la souris sur un group de pin, on met en surbrillance les prop qui sont dedans
            google.maps.event.addListener(markerCluster, 'mouseover', function() {
                for(var i=0, clusterLen = markerCluster.clusters_.length;i<clusterLen;i++) {
                    var markersLen = markerCluster.clusters_[i].markers_.length;
                    if(markersLen > 1) { // Ici on voit sur le cluster est un marker de type groupe, il contient plus de 1, donc groupe CQFD
                        for(var j=0; j<markersLen; j++) {
                            // On parcourt tous les markers pour s'assurer que .map est null, donc le marker est bien caché.
                            if(markerCluster.clusters_[i].markers_[j].map == null) {
                                // Ensuite on récupérer la propriété et on surligne
                                $(markerCluster.clusters_[i].markers_[j].property).addClass('highlight');
                            }
                        }
                    }
                }
            });
            google.maps.event.addListener(markerCluster, 'mouseout', function() {
                for(var i=0, clusterLen = markerCluster.clusters_.length;i<clusterLen;i++) {
                    var markersLen = markerCluster.clusters_[i].markers_.length;
                    if(markersLen > 1) { // Ici on voit sur le cluster est un marker de type groupe, il contient plus de 1, donc groupe CQFD
                        for(var j=0; j<markersLen; j++) {
                            // On parcourt tous les markers pour s'assurer que .map est null, donc le marker est bien caché.
                            if(markerCluster.clusters_[i].markers_[j].map == null) {
                                // Ensuite on récupérer la propriété et on surligne
                                $(markerCluster.clusters_[i].markers_[j].property).removeClass('highlight');
                            }
                        }
                    }
                }
            });

        }
        else {
            // markerCluster = new MarkerClusterer(map, MARKERS, {
            //     maxZoom: 20,
            //     imagePath: Config.clusterImageURL
            // });
        }
        // google.maps.event.addDomListener(window, 'resize', constrainArea);
        constrainArea();
    }
    function refresh() {
        // google.maps.event.trigger(map, "resize");
        constrainArea();
    }
    function createMarker(myLatLng, property) {
        var marker, photo;
        if(Config.mapInfoWindow) {
            var image = {
                url: Config.image_url + 'map-icon.png',
                size: new google.maps.Size(24, 32),
                origin: new google.maps.Point(0, 0)
            };
            var image1 = {
                url: Config.image_url + 'map-icon1.png',
                size: new google.maps.Size(24, 32),
                origin: new google.maps.Point(0, 0)
            };
            marker = new google.maps.Marker({
                position: myLatLng,
                map: map,
                icon: image
            });
            if(property.hasClass('ui-property-msc')) {
                if(property.find('.msc-photo').css('background-image')) {
                    photo = property.find('.msc-photo').css('background-image').replace('url(','').replace(')','').replace('"','');
                }
            } else {
                if(property.find('.list-photo').css('background-image')) {
                    photo = property.find('.list-photo').css('background-image').replace('url(','').replace(')','').replace('"','');
                }
            }
            var contentString = '<div class="gm-style-iw">' + (property.find('.ui-property-msc').html() ? property.find('.ui-property-msc').html() : property.html()) + '</div>';
            var infowindow = new google.maps.InfoWindow({
                content: contentString
            });
            google.maps.event.addListener(infowindow, 'domready', function() {
                var iwOuter = $('.gm-style-iw');
                var iwBackground = iwOuter.prev();
                iwBackground.children(':nth-child(2)').css({'display' : 'none'});
                iwBackground.children(':nth-child(4)').css({'display' : 'none'});
                // Moves the infowindow 115px to the right.
                //iwOuter.parent().parent().css({left: '115px'});
                // Moves the shadow of the arrow 76px to the left margin.
                //iwBackground.children(':nth-child(1)').attr('style', function(i,s){ return s + 'left: 76px !important;'});
                // Moves the arrow 76px to the left margin.
                //iwBackground.children(':nth-child(3)').attr('style', function(i,s){ return s + 'left: 76px !important;'});
                var iwCloseBtn = iwOuter.next();
                iwCloseBtn.css({
                    opacity: '1',
                    right: '58px',
                    'background-color': '#fff',
                    top: '20px'
                });
                iwCloseBtn.mouseout(function(){
                    $(this).css({opacity: '1'});
                });
            });

        } else {
            if(Config.template == 'REFLEX' && map !== null) {
                marker = new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                    url: property.find('a').attr('href'),
                    icon: '/images/'+Config.template+'/icon-gps.png',
                    animation: google.maps.Animation.DROP, 
                    property: property // <- Clef personnelle
                });
            }
            else if(Config.template == 'UNUSED') {
                var pos = new google.maps.LatLng(myLatLng.lat, myLatLng.lng);
                marker = new CustomMarker({
                    position: pos,
                    map: map,
                    url: property.find('a').attr('href'),
                    property: property // <- Clef personnelle
                });
            }
            else {
                if (map !== null) {
                    marker = new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                    url: property.find('a').attr('href')
                    });
                }
            }
        }
        if (map !== null) {
            google.maps.event.addListener(marker, 'click', function() {
            if(Config.mapInfoWindow) {
                if(lastOpened) { lastOpened.close(); }
                infowindow.open(map, marker);
                lastOpened = infowindow;
            } else {
                window.location.href = this.url;
            }
            });
        }
        

        // Lorsqu'on met la souris sur un icon, on met en surbrillance la ligne
        if(Config.template == 'REFLEX' && map !== null) {
            google.maps.event.addListener(marker, 'mouseover', function() {
                $(this.property).addClass('highlight');
            });
            google.maps.event.addListener(marker, 'mouseout', function() {
                $(this.property).removeClass('highlight');
            });
        }


        if(Config.mapInfoWindow) {
            google.maps.event.addListener(marker, 'mouseover', function () {
                marker.setIcon(image1);
            });
            google.maps.event.addListener(marker, 'mouseout', function () {
                marker.setIcon(image);
            });
        }
        MARKERS.push(marker);
    }
    function bounceMarker(ref) {
        if(REFS.indexOf(ref) > -1 && map !== null) {
            for (var j = 0; j < MARKERS.length; j++) {
                MARKERS[j].setAnimation(null);
            }
            if (MARKERS[REFS.indexOf(ref)].getAnimation() != google.maps.Animation.BOUNCE) {
                MARKERS[REFS.indexOf(ref)].setAnimation(google.maps.Animation.BOUNCE);
            }

            if(MARKERS[REFS.indexOf(ref)].getAnimation() == 'KevinCustomAnimation') {
                MARKERS[REFS.indexOf(ref)].animateBounce();
            }
            
        }
    }
    function constrainArea() {
        if(MARKERS.length > 0 && map !== null) {
            var BOUNDS = new google.maps.LatLngBounds();
            for (var i = 0; i < MARKERS.length; i++) {

                BOUNDS.extend(MARKERS[i].getPosition());
            }
            map.fitBounds(BOUNDS);
            map.setZoom(map.getZoom() - 1);

            if (MARKERS.length === 1) {
                map.setOptions({
                    maxZoom: 15
                });
            }
        }
    }
    function toggleMap(open) {
        var percentMarg, percentTranslate, footer = $('main.main.list footer'),
            mapContainer = $('main.main.list .map'), properties = $('.ui-property-msc, .ui-property'),
            content = $('.content'), btnMap = $('.btn-map');
        switch(open) {
            case true:
                localStorage.setItem('map', 'false');
                percentMarg = '41.66666667%';
                percentTranslate = '0';
                if (!mapInitialized) {
                    // GoogleMap.init();
                    mapInitialized = true;
                }
                properties.each(function () {
                    $(this).addClass('with-map');
                });
                footer.css('display', 'none');
                break;
            case false:
                localStorage.setItem('map', 'true');
                percentMarg = '0';
                percentTranslate = '100';
                properties.each(function () {
                    $(this).removeClass('with-map');
                });
                footer.css('display', 'block');
                break;
        }
        btnMap.css('margin-right', percentMarg);
        content.css('margin-right', percentMarg);
        mapContainer.css({
            'transform': 'translateX(' + percentTranslate + '%)',
            '-o-transform': 'translateX(' + percentTranslate + '%)',
            '-ms-transform': 'translateX(' + percentTranslate + '%)',
            '-moz-transform': 'translateX(' + percentTranslate + '%)',
            '-webkit-transform': 'translateX(' + percentTranslate + '%)'
        });
    }
    function searchNearby(serviceMap) {
        var service = new google.maps.places.PlacesService(serviceMap),
            serviceInfowindow, contentString, map = serviceMap;
        service.nearbySearch({
            location: serviceMap.getCenter(),
            radius: 5000
            //types: ['store, restaurant, school, establishment']
        }, callback);
        function callback(results, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                for (var i = 0; i < results.length; i++) {
                    createServiceMarker(results[i]);
                }
            }
        }
        function createServiceMarker(place) {
            var image = {
                url: place.icon,
                scaledSize: new google.maps.Size(20, 20),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(0, 0)
            }, serviceMarker = new google.maps.Marker({
                map: serviceMap,
                place: {
                    placeId: place.place_id,
                    location: place.geometry.location
                },
                icon: image
            });
            google.maps.event.addListener(serviceMarker, 'click', function() {
                contentString =
                    '<div class="poi-info-window gm-style" style="max-width: 200px;color: #333;font-family: Roboto,Arial,sans-serif;font-size: 13px;background-color: white; -moz-user-select: text; -webkit-user-select: text; -ms-user-select: text;user-select: text;">' +
                        '<div class="title full-width" style="font-size: 14px;font-weight: 500;">' +
                            place.name +
                        '</div>' +
                        '<div class="address">' +
                            '<div class="address-line full-width" >' +
                                place.vicinity +
                            '</div>' +
                        '</div>' +
                    '</div>';
                serviceInfowindow = new google.maps.InfoWindow({
                    content: contentString
                });
                if(lastOpened) { lastOpened.close(); }
                serviceInfowindow.open(map, this);
                lastOpened = serviceInfowindow;
                map.panTo(place.geometry.location);
            });
        }
    }
    return {
        init:init,
        toggleMap: toggleMap,
        searchNearby: searchNearby,
        refresh: refresh
    }
})();

var Slider = (function() {
    "use strict";
    var slider = $('.slick-slider');
    function init() {
        slider.slick({
            lazyLoad: 'ondemand',
            variableWidth: true,
            centerMode: true,
            centerPadding: '0px',
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '0px',
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '0px',
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
    return {
        init: init
    }
})();

var MultiSelect = (function() {
    "use strict";
    var dropdowns = [{'elem':'.custom-dropdown', 'btn':'.btn-custom-dropdown'}];
    function init() {
        $(".chosen-select").chosen({no_results_text: "Aucun résultats trouvés"});
        for(var i = 0; i < dropdowns.length; i++) {
            CustomDropDown.init($(dropdowns[i].elem), $(dropdowns[i].btn));
        }
    }
    return {
        init:init
    }
})();

var CustomDropDown = {
    init:function($element, $btn) {
        this.bindUIActions($element, $btn);
    },
    bindUIActions:function($element, $btn) {
        $element.each(function(){
            var _this = $(this), val, html, select = _this.parents().eq(2).find('select'), whiteIcon = select.hasClass('svg-white');
            if(select.attr('placeholder') != undefined) {
                if(Config.listIcon == 'arrow') {
                    html = select.attr('placeholder') + '<img class="cdd-svg" src="' + Config.image_url + (whiteIcon ? 'svg/caret.svg">' : 'svg/caret-blk.svg">');
                } else {
                    html = select.attr('placeholder') + '<span class="caret caret-down"></span>';
                }
                _this.parent().find('.btn-custom-dropdown').html(html);
            }
            select.find('option').each(function(i){
                if($(this).attr('selected') == 'selected') {
                    var selected = $(this);
                    CustomDropDown.updateButton(_this.find('li:nth-child(' + (i + 1) + ')'), _this);
                }
            });
            _this.css('width', _this.parent().find('.btn-custom-dropdown').css('width'));
            _this.find('li').on("click", function() {
                _this.toggle();
                CustomDropDown.updateButton($(this), _this);
                val = $(this).index();
                select = _this.parents().eq(2).find('select');
                select.find('option').each(function(){
                    if(typeof $(this).val() == "string" && $(this).val().indexOf('?') > -1) {
                        val > 0 ? val++ : null;
                    }
                    $(this).removeAttr('selected');
                });
                select.find('option:eq(' + val + ')').attr('selected', 'selected');
                var temp;
                if(isNaN(select.find('option:eq(' + val + ')').val())) {
                    temp = select.find('option:eq(' + val + ')').val();
                    if(typeof temp == "string" && temp.indexOf('?') > -1) {
                        val = 0; select.val(0);
                    }
                }
                if(Config.listIcon == 'arrow' && Config.page == 'list' && Config.template.indexOf('SANDIE') != -1) {
                    if(!_this.hasClass('tri-prop')) {
                        localStorage.setItem('pop', 'plus');
                        $('#submit').trigger('click');
                    }
                }
            });
        });
        $btn.each(function(){
            $(this).on("click", function(e) {
                e.preventDefault();
                e.stopPropagation();
                if(Config.listIcon == 'arrow') {
                    $(this).find('.cdd-svg').toggleClass('flipV');
                } else {
                    $(this).find('.caret').hasClass('caret-down') ? $(this).find('.caret').removeClass('caret-down').addClass('caret-up') : $(this).find('.caret').removeClass('caret-up').addClass('caret-down');
                }
                $('.custom-dropdown').css('display', 'none!important');
                $(this).parent().find('.custom-dropdown').toggle();
            });
        });
    },
    updateButton:function(li, ul) {
        var html, whiteIcon = ul.parents().eq(2).find('select').hasClass('svg-white');
        if(Config.listIcon == 'arrow') {
            html = li.text() + '<img class="cdd-svg" src="' + Config.image_url + (whiteIcon ? 'svg/caret.svg">' : 'svg/caret-blk.svg">');
            li.find('.cdd-svg').toggleClass('flipV');
        } else {
            html = li.text() + '<span class="caret caret-down"></span>';
        }
        ul.parent().find('.btn-custom-dropdown').html(html);
    },
    updateAll:function() {
        $('.custom-dropdown').each(function() {
            var _this = $(this);
            _this.parents().eq(2).find('select option').each(function () {
                if ($(this).attr('selected') == 'selected') {
                    var selected = $(this);
                    CustomDropDown.updateButton(_this.find('li:nth-child(' + (i + 1) + ')'), _this);
                }
            });
        });
    }
};

var ChosenTouch = (function() {
    "use strict";
    function init() {
        if ($('.chosen-container').length > 0) {
            $('.chosen-container').on('touchstart', function(e){
                e.stopPropagation(); e.preventDefault();
                $(this).trigger('mousedown');
            });
        }
    }
    return {
        init:init
    }
})();

var Calculette = (function() {
    "use strict";
    function init(params) {
        $(".calculator-loan").accrue({
            default_values: {
                amount: params.price,
                rate: params.rate,
                term: params.term
            },
            field_titles: {
                amount: params.trad.amount,
                rate: params.trad.rate,
                term: params.trad.term
            },
            button_label: params.trad.label,
            field_comments: {
                amount: "",
                rate: "",
                rate_compare: "",
                term: ""
            },
            response_output_div: ".results",
            response_basic:
            '<span class="h4"><strong>%payment_amount%&nbsp;&euro;</strong><span> ' + params.trad.month + '</span></span>'+
            '<p class="h6">' + params.trad.total + ' <span>%total_payments%&nbsp;&euro;</span></p>'+
            '<p class="h6">' + params.trad.total_price + ' <span>%total_interest%&nbsp;&euro;</span></p>',
            error_text: '<h5>' + params.trad.error + '</h5>',
            out_of_range: '<h5>' + params.trad.out_of_range + '</h5>',
            over_max_rate: '<h5>' + params.trad.over_max_rate + '</h5>',
            over_max_amount: '<h5>' + params.trad.over_max_amount + '</h5>',
            callback: function ( elem, data ){}
        });
        bindUIActions();
    }
    function bindUIActions() {
        $('a.from').text( 'À partir de ' + $('.calculator-loan:first .h4').text()).click(function(){
            $('html, body').animate({
                scrollTop: $("#calculette").offset().top - 50
            }, 500);
        });
    }
    return {
        init: init
    }
})();
var userAgentDefault = navigator.userAgent;
$(document).ready(function() {
    if(jQuery().chosen !== undefined) {
        navigator.__defineGetter__('userAgent', function(){
            return 'ChosenJS change user agent for iOS Fix'
        });
        $('.chosen-select').chosen({ search_contains: true });
        navigator.__defineGetter__('userAgent', function(){
            return userAgentDefault
        });
    }
});
